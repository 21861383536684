// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-carreiras-js": () => import("./../src/pages/carreiras.js" /* webpackChunkName: "component---src-pages-carreiras-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-cursos-info-adm-js": () => import("./../src/pages/cursos/info_adm.js" /* webpackChunkName: "component---src-pages-cursos-info-adm-js" */),
  "component---src-pages-franquias-js": () => import("./../src/pages/franquias.js" /* webpackChunkName: "component---src-pages-franquias-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscrever-js": () => import("./../src/pages/inscrever.js" /* webpackChunkName: "component---src-pages-inscrever-js" */),
  "component---src-pages-sobre-js": () => import("./../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-curso-js": () => import("./../src/templates/curso.js" /* webpackChunkName: "component---src-templates-curso-js" */)
}

